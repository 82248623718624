// External Dependencies
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import React from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import CurveSection from '../components/shared/CurveSection';
import HeaderCurve from '../components/HeaderCurve';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Component definition
const Switch = () => (
  <Layout variant="curve">
    <Meta title="Switch" />

    <HeaderCurve
      subtitle="Get Presto for a fresh start for your fine arts program!"
      title="Switch to Presto"
    />

    <div className="switch" id="main">
      <CurveSection
        className="main"
        id="content"
      >
        <Container maxWidth="md">
          <Card className="switch-card" variant="outlined">
            <CardHeader title="Switch to Presto Assistant" />

            <CardContent>
              <Typography paragraph>
                Start up a free trial on your own in a few minutes.
              </Typography>

              <Typography paragraph>
                Get access to most of the features of Presto Assistant for 30 days.
              </Typography>

              <Typography paragraph>
                Invite staff members to explore Presto with you.
              </Typography>

              <Typography>
                Start your free trial today!
              </Typography>
            </CardContent>
          </Card>
        </Container>

        <Box className="panel">
          <a
            className="transparent-button"
            data-variant="transport"
            href="https://www.presto-assistant.com/trial"
          >
            Get Started
          </a>
        </Box>
      </CurveSection>
    </div>
  </Layout>
);

export default Switch;
